import styled from 'styled-components'
import { CampaignPromoVariant } from '@/types'
import { BREAKPOINT_SMALL, BREAKPOINT_MEDIUM, Z_INDEX } from '@/constant/ui'

export const Wrapper = styled.div<{ variant: CampaignPromoVariant }>`
  position: relative;

  ${({ variant }) =>
    (variant === 'edgy' &&
      `
    height: 80vh;
    min-height: 700px;
  `) ||
    (variant === 'video' &&
      `
    z-index: ${Z_INDEX.medium};
    margin-bottom: -60px;
    
    @media (max-width: ${BREAKPOINT_SMALL}px) {
      margin-bottom: -20px;
    }
  `)}
`
export const Title = styled.div<{ inverse?: boolean }>`
  flex-shrink: 0;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
  }
`
export const VideoWrap = styled.div`
  clip-path: polygon(0% 0%, 100% 0%, 100% 92.3%, 0% 100%);

  > div {
    position: relative;
    z-index: ${Z_INDEX.low};
    padding-top: 50vh;
    padding-bottom: 100px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: ${Z_INDEX.base};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    clip-path: polygon(0% 0%, 100% 0%, 99.9% 97.3%, 0% 100%);
  }
  @media (min-width: ${BREAKPOINT_SMALL}px) {
    ${Title} {
      span {
        font-size: 164px;
      }
    }
  }
`
export const Content = styled.div`
  width: 1296px;
  max-width: 90%;
  margin: 0 auto;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  bottom: 15%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    bottom: auto;
    top: 8%;
  }
`
export const StandardWrap = styled.div`
  > div {
    height: 60vh;
    min-height: 700px;
  }

  .text-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  @media (max-width: ${BREAKPOINT_SMALL}px) {
    > div {
      height: 48vh;
      min-height: 400px;
    }
  }
`
export const EdgyWrap = styled.div`
  > div {
    height: 80vh;
    min-height: 700px;

    @media (max-width: ${BREAKPOINT_SMALL}px) {
      height: 75vh;
      min-height: unset;
    }
  }
  .edgy-image-layer {
    transform-origin: top right;
  }
`
export const EdgyImage = styled.div`
  position: absolute;
  width: 65%;
  height: 85%;
  right: 0;
  top: 0;
  box-sizing: border-box;
  pointer-events: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 80%;
    top: 8%;
  }
`
export const HeadingContain = styled.div`
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
export const BlocksWrap = styled.div`
  max-width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
`
export const BlocksContainer = styled.div<{ rowReverse: boolean; height?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: ${({ rowReverse }) => (rowReverse ? 'row-reverse' : 'row')};
  width: 100%;

  > div {
    width: 50%;
    &:nth-child(1) {
      height: ${({ height }) => (height ? height : 'auto')};
    }
    &:nth-child(2) {
      height: ${({ height }) => (height ? height : 'auto')};
      position: relative;
      top: 20px;
    }
  }

  h2 {
    color: var(--brand-primary);
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    display: block;

    > div:nth-child(1) {
      height: auto;
      width: 100%;
    }
    > div:nth-child(2) {
      height: auto;
      width: 100%;
      top: -20px;
    }
  }
`
export const ImageBlock = styled.div`
  width: 100%;
  height: 100%;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 100%;
    height: 220px;
  }
`
export const TextBlock = styled.div`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--brand-secondary);
  padding: 10%;
  box-sizing: border-box;

  ${Title} {
    > div {
      line-height: 64px;
    }
    span {
      font-size: 88px;
      top: 2px;
    }
  }

  @media (max-width: ${BREAKPOINT_MEDIUM}px) {
    width: 100%;
    min-height: unset;
    padding: 8% 25px;
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    ${Title} {
      > div {
        line-height: 36px;
      }
      span {
        font-size: 44px;
      }
    }
  }
`
