import { CampaignPromoVariant } from '@/types'
import Image from 'next/image'
import { Spacing, FlexibleHeading as Heading, Body } from '@big-red-group/storefront-common-ui'
import Button from '@/components/Button'
import Container from '@/components/Container'
import { ParallaxProvider, Parallax, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax'
import {
  Wrapper,
  Title,
  EdgyImage,
  Content,
  VideoWrap,
  HeadingContain,
  BlocksContainer,
  ImageBlock,
  TextBlock,
  StandardWrap,
  EdgyWrap,
  BlocksWrap,
} from '@/components/CampaignPromo.styles'

const CampaignPromo: React.FC<{
  variant: CampaignPromoVariant
  media: string
  title?: string
  body?: string
  buttonLabel?: string
  url?: string
  customContent?: boolean
  children?: React.ReactNode
  rowReverse?: boolean
  height?: string
}> = ({
  variant,
  media,
  title,
  buttonLabel,
  url,
  children,
  customContent = false,
  rowReverse = false,
  body,
  height,
}) => {
  const handleButtonClick = () => {
    if (!url) return
    window.location.href = url
  }

  const renderContent = () => {
    if (variant === 'video') {
      return (
        <VideoWrap data-testid="video-campaign">
          <video src={media} loop autoPlay muted></video>
          <Container>
            {!customContent ? (
              <HeadingContain>
                <Heading weight="fancy" size="XL" as="h2">
                  {title && <div dangerouslySetInnerHTML={{ __html: title }}></div>}
                </Heading>
                <Spacing size="L" />
                {url && buttonLabel && (
                  <Button size="L" onClick={() => handleButtonClick()}>
                    {buttonLabel}
                  </Button>
                )}
              </HeadingContain>
            ) : (
              children
            )}
          </Container>
        </VideoWrap>
      )
    } else if (variant === 'edgy') {
      return (
        <EdgyWrap>
          <ParallaxProvider>
            <ParallaxBanner>
              <ParallaxBannerLayer
                expanded={false}
                speed={-20}
                scale={[1, 1.3, 'easeOutCubic']}
                className="edgy-image-layer"
              >
                <EdgyImage data-testid="edgy-campaign">
                  <Image src={media} alt="Campaign promo" width={0} height={0} sizes="100%" loading="lazy" />
                </EdgyImage>
              </ParallaxBannerLayer>
              <ParallaxBannerLayer expanded={false} speed={-5}>
                <Content>
                  {!customContent ? (
                    <>
                      <Heading weight="fancy" size="XL" as="h2">
                        {title && <div dangerouslySetInnerHTML={{ __html: title }}></div>}
                      </Heading>
                      <Spacing size="L" />
                      {url && buttonLabel && (
                        <Button size="L" onClick={() => handleButtonClick()}>
                          {buttonLabel}
                        </Button>
                      )}
                    </>
                  ) : (
                    children
                  )}
                </Content>
              </ParallaxBannerLayer>
            </ParallaxBanner>
          </ParallaxProvider>
        </EdgyWrap>
      )
    } else if (variant === 'blocks') {
      return (
        <BlocksWrap>
          <Container>
            <BlocksContainer data-testid="blocks-campaign" rowReverse={rowReverse} height={height}>
              <ParallaxProvider>
                <Parallax speed={-4} translateY={['0', '0']} translateX={['-20px', '20px']}>
                  <ImageBlock>
                    <Image src={media} alt="Campaign promo" width={0} height={0} sizes="100%" loading="lazy" />
                  </ImageBlock>
                </Parallax>
              </ParallaxProvider>
              <ParallaxProvider>
                <Parallax speed={4} translateY={['0', '0']} translateX={['20px', '-20px']}>
                  <TextBlock>
                    <div>
                      {!customContent ? (
                        <>
                          <Heading weight="fancy" size="XL" as="h2" color="brand-primary">
                            {title && <div dangerouslySetInnerHTML={{ __html: title }}></div>}
                          </Heading>
                          {body && (
                            <>
                              <Spacing size="S" />
                              <Body color="text-inverse">{body}</Body>
                            </>
                          )}
                          <Spacing size="XL" />
                          {url && buttonLabel && (
                            <Button size="M" onClick={() => handleButtonClick()}>
                              {buttonLabel}
                            </Button>
                          )}
                        </>
                      ) : (
                        children
                      )}
                    </div>
                  </TextBlock>
                </Parallax>
              </ParallaxProvider>
            </BlocksContainer>
          </Container>
        </BlocksWrap>
      )
    } else {
      return (
        <StandardWrap>
          <ParallaxProvider>
            <ParallaxBanner>
              <ParallaxBannerLayer speed={-20} image={media} />
              <ParallaxBannerLayer speed={-5} expanded={false}>
                <Container $class="text-content">
                  {!customContent ? (
                    <>
                      <Title inverse data-testid="standard-campaign">
                        <Heading weight="fancy" size="XXL" as="div">
                          {title && <div dangerouslySetInnerHTML={{ __html: title }}></div>}
                        </Heading>
                      </Title>
                      <Spacing size="L" />
                      {url && buttonLabel && (
                        <Button size="L" onClick={() => handleButtonClick()}>
                          {buttonLabel}
                        </Button>
                      )}
                    </>
                  ) : (
                    children
                  )}
                </Container>
              </ParallaxBannerLayer>
            </ParallaxBanner>
          </ParallaxProvider>
        </StandardWrap>
      )
    }
  }

  return <Wrapper variant={variant}>{renderContent()}</Wrapper>
}

export default CampaignPromo
